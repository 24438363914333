<template>
  <div >
    <div class="qxt_box">
      <img src="../images/qxt_bg.png"  class="qxt_bg" />
      <div class="qxt_content">
        <div class="title">科技创新服务平台</div>
        <div class="qxt_search">
          <div class="qxt_tab">
            <div class="tab_item" 
              v-for="(item, index) in tabList" :key="index"
              :class="tabIndex ===  index ? 'tab_item_ac' : ''"
              @click="changeTab(index)"
              >
              {{item.name}}
            </div>
          </div>
          <div class="input_box">
            <div class="search_input">
              <input :placeholder="placeholder" class="input_m" v-model="searchIndex"/>
            </div>
            <div class="search_btn" @click="search">
              <img src="../images/search_icon.png"/>
              <span class="search_fs">搜  索</span>
            </div>
          </div>
          <div class="hot_box">
            <div class="hot_title">
              <div>热门</div><div>主题</div>
            </div>
            <div class="hot_list">
              <div class="hot_item" v-for="(item, index) in hotList" @click="searchHot(item)">
                <span class="hot_bm">{{item}}</span>
              </div>
            </div>
          </div>
          <div class="hot_box">
            <div class="hot_title">
              <div>产业</div><div>报告</div>
            </div>
            <div class="report_list">
              <div class="report_item" v-for="(item, index) in reportList" :key="index">
                <div class="report_up" >
                  <div class="report_fs1">{{item.name}}</div>
                </div>
                <div class="report_down" @click="openUrl(item.url)">查看报告</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-html="htmlStr"></div>
  </div>
</template>

<script>
  import {
    // 找专家接口
    seekExpert,
    // 找机构接口
    seekOrg,
    // 技术报告接口
    getDemandDetailById,
    // 专家报告接口
    exportController,
    // 机构报告接口
    orgController,
  } from "@/plugins/api/wfApi";

  // 万方地址
  import {wfUrl} from "@/plugins/http/baseUrl";
  export default {
    name: "esc_index",
    data() {
      return {
        // tabs索引
        tabIndex: 0,
        // 查询值
        searchIndex: "",
        placeholder: "",
        htmlStr: "",
        reportList:[
          // {name: "枸杞加工产业技术匹配报告", url: "https://downloadzgapk.oss-cn-shanghai.aliyuncs.com/baogao/gouqijiagong.pdf"},
          {name: "新材料产业技术匹配报告", url: "http://cnuip.net/baogao/%E6%96%B0%E6%9D%90%E6%96%99%E4%BA%A7%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%8C%B9%E9%85%8D%E6%8A%A5%E5%91%8A.pdf"},
          {name: "“生物医药”产业技术匹配报告", url: "http://cnuip.net/baogao/%E2%80%9C%E7%94%9F%E7%89%A9%E5%8C%BB%E8%8D%AF%E2%80%9D%E4%BA%A7%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%8C%B9%E9%85%8D%E6%8A%A5%E5%91%8A.pdf"}
        ]
      }
    },
    props: {
      // tab列表
      tabList: {
        type: Array,
        default: () => {
          return [
            {name: "找专家", placeholder: "请输入技术点，查找相关专家", key: "ckeys", path: "/seekExpert.do?toExpertsRecommend&input=none&",enCount: 2},
            {name: "懂专家", placeholder: "请输入您想查询的专家姓名", key: "name", path: "/exportController.do?toExportParam&input=none&",enCount: 1},
            {name: "识技术", placeholder: "请输入技术点关键字", key: "ckey", path: "/ckeyController.do?toCkeyParam&input=none&",enCount: 1},
            {name: "找机构", placeholder: "请输入技术点，查找相关机构", key: "ckeys", path: "/seekOrg.do?toOrgsRecommend&input=none&",enCount: 2},
            {name: "知机构", placeholder: "请输入机构名称关键字", key: "org", path: "/orgController.do?toOrgParam&input=none&",enCount: 1}
          ]
        }
      },
      // 热点列表
      hotList: {
        type: Array,
        default: () => {
          return ["高端装备", "轨道交通", "汽车制造", "新材料", "新能源", "生物医药", "新型纺织服装", "高性能复合材料"]
        }
      }
    },
    watch: {
      tabList: {
        deep: true,
        handler(newVal,oldVal) {
          this.tabList = newVal
        }
      },
      hotList: {
        deep: true,
        handler(newVal,oldVal) {
          this.hotList = newVal
        }
      }
    },
    mounted(){
      this.placeholder = this.tabList[this.tabIndex].placeholder
    },
    methods: {
      /*
       * @desc tab切换事件
       * @param {number} index 切换索引
       */
      changeTab(index){
        this.tabIndex = index;
        this.placeholder = this.tabList[index].placeholder
      },

      /*
       * @desc 热点搜索
       * @param {string} item 选中热点
       */
      searchHot(item) {
        this.searchIndex = item;
        this.search()
      },

      /*
       * @desc 获取url
       */
      getUrl() {
        // 选中查询类型
        let data = this.tabList[this.tabIndex];
        let { key, path, enCount } = data;
        let str = this.searchIndex;
        // 包含中文
        if(/.*[\u4e00-\u9fa5]+.*$/.test(str)){
          for (let i = 0; i < enCount; i++) {
            str = encodeURI(str)
          }
        }
        let url = `${wfUrl}${data.path}${data.key}=${str}`
        return url
      },

      /*
       * @desc 搜索
       */
      search(){
        this.searchIndex && this.$router.push({name: 'qxt_result',params: {url: this.getUrl()}})
      },
      openUrl(url) {
        window.open(url)
      }
    }
  }
</script>

<style scoped lang="scss">
  .qxt_bg{
    min-width: 1200px;
    width: 1920px;
    height: 695px;
  }
  .qxt_box{
    width: 100%;
    position: relative;
    height: 695px;
  }
  .qxt_content{
    width: 100%;
    position: absolute;
    top: 0;
  }
  .title{
    margin-top: 165px;
    text-align: center;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 56px;
  }
  .qxt_search{
    width: 810px;
    margin: 60px auto 0px;
  }
  .qxt_tab{
    display: flex;
  }
  .tab_item{
    text-align: center;
    width: 80px;
    height:44px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
    cursor: pointer;
  }
  .tab_item_ac{
    color: #1890FF;
    background: url("../images/qxt_tab_ac.png") no-repeat;
    background-size:100% 100%;
  }
  .input_box{
    display: flex;
    height: 60px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 14px
  }
  .search_input{
    width: 694px;
    display: flex;
  }
  .input_m{
    width: 500px;
    border:0px;
    outline:none;
    margin-left: 20px;
    color: #333333;
  }
  .search_btn{
    vertical-align:middle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    height: 60px;
    background: #1890FF;
    border-radius: 5px;
    cursor: pointer;
  }
  .search_fs{
    display: flex;
    margin-left: 12px;
    width: 46px;
    height: 18px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
  }

  .hot_box{
    display: flex;
    margin-top: 24px;

  }
  .hot_title{
    text-align: center;
    padding: 10px 0px;
    width: 70px;
    height: 70px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.9);
  }
  .hot_list{
    width: 600px;
    margin-left: 28px;
    display: flex;
    flex-wrap: wrap;
  }
  .hot_item{
    margin-top: 8px;
    margin-right: 30px;
    text-align: left;
    /*width: 84px;*/
    cursor: pointer;
  }
  .hot_bm{
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }
  .hot_bm:hover{
    border-bottom: 1px solid rgba(24, 144, 255, 0.7);
  }
  .hot_bm:hover{
    color: rgba(24, 144, 255, 0.7);
  }
  .search_input input::-webkit-input-placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  .search_input input::-moz-placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  .search_input input:-moz-placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }

  .report_item{
    margin-right: 12px;
    width: 220px;
    height: 70px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
  }
  .report_list{
    margin-left: 28px;
    display: flex;
  }
  .report_up{
    height: 38px;
    padding-top: 10px;
  }
  .report_down{
    height: 32px;
    background: #1890FF;
    border-radius: 0px 0px 4px 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .report_fs1{
    text-align: center;
    /*margin-top: 14px;*/
    font-size: 14px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.9);
    line-height: 18px;
  }
  .report_fs2{
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 0.9);
    line-height: 14px;
  }
</style>
